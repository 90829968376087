import CryptoJS from 'crypto-js';

// Function to encrypt data (specifically for string data like an access token)
export function encryptAccessToken(data: string): string {
  return CryptoJS.AES.encrypt(
    data,
    process.env.REACT_APP_MAGIC_KEY || ''
  ).toString();
}

// Function to decrypt data
export function decryptAccessToken(encryptedData: string): string {
  const bytes = CryptoJS.AES.decrypt(
    encryptedData,
    process.env.REACT_APP_MAGIC_KEY || ''
  );
  return bytes.toString(CryptoJS.enc.Utf8);
}

// Function to save encrypted data to localStorage
export function setEncryptedAccessToken(data: string): void {
  const encryptedToken = encryptAccessToken(data);
  localStorage.setItem('accessToken', encryptedToken);
}

// Function to retrieve and decrypt the token from localStorage
export function getDecryptedAccessToken() {
  const encryptedToken = localStorage.getItem('accessToken');
  if (encryptedToken) {
    try {
      return decryptAccessToken(encryptedToken);
    } catch (e) {
      console.error('Failed to decrypt accessToken', e);
      return null;
    }
  }
  return null;
}
