import Dexie, { Table } from 'dexie';
import CryptoJS from 'crypto-js';

type RQueryCache = {
  key: string;
  value: string;
};

class LSDataDexie extends Dexie {
  lsCache!: Table<RQueryCache, string>;

  constructor() {
    super('LSCache');
    try {
      this.version(1).stores({
        lsCache: 'key',
      });
    } catch (error) {
      console.error('Dexie initialization error:', error);
    }
  }
}

const db = new LSDataDexie();

export const encryptData = (data: any): string => {
  return CryptoJS.AES.encrypt(
    JSON.stringify(data),
    process.env.REACT_APP_SECRET_KEY || ''
  ).toString();
};

export const decryptData = (encryptedData: string): any => {
  try {
    const bytes = CryptoJS.AES.decrypt(
      encryptedData,
      process.env.REACT_APP_SECRET_KEY || ''
    );
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.error('Decryption error:', error);
    return null;
  }
};

export const saveData = async (key: string, data: string): Promise<void> => {
  const encryptedData = encryptData(data);
  await db.lsCache.put({ key, value: encryptedData });
};

export const getData = async (
  key: string
): Promise<RQueryCache | undefined> => {
  const result = await db.lsCache.get(key);
  if (result) {
    result.value = decryptData(result.value);
  }
  return result;
};

export const clearAllData = async (): Promise<void> => {
  await db.lsCache.clear();
};

export const deleteKeyStore = async (key: string): Promise<void> => {
  await db.lsCache.delete(key);
};
