import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Authenticator } from '../helper/Authenticator';

interface PublicRouteProps {
  isAuth: boolean;
  to?: string;
}

const PublicRoute: React.FC<PublicRouteProps> = ({
  isAuth,
  to = '/overview',
}) => {
  if (Authenticator.isLoading) {
    return null;
  }
  if (isAuth) {
    return <Navigate to={to} replace />;
  }
  return <Outlet />;
};

export default PublicRoute;
