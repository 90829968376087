export class ExecutionLimiter {
  static throttle<T extends (...args: any[]) => void>(
    func: T,
    interval: number
  ): (...args: Parameters<T>) => void {
    let isRunning = false;
    return function (this: any, ...args: Parameters<T>) {
      if (!isRunning) {
        isRunning = true;
        func.apply(this, args);
        setTimeout(() => {
          isRunning = false;
        }, interval);
      }
    };
  }
}
