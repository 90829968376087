import React, { useState, useEffect, Suspense } from 'react';
import './sass/Main.scss';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import Sidebar from './components/Sidebar';
import ProtectedRoute from './utils/ProtectedRoute';
import PublicRoute from './utils/PublicRoute';
import { Authenticator } from './helper/Authenticator';

// Lazy loading each component
const Profile = React.lazy(() => import('./components/Profile'));
const Login = React.lazy(() => import('./components/Login'));
const Overview = React.lazy(() => import('./components/Overview'));
const Property = React.lazy(() => import('./components/Property'));
const VerifyEmail = React.lazy(() => import('./components/VerifyEmail'));
const Notification = React.lazy(() => import('./components/Notification'));
const Request = React.lazy(() => import('./components/Request'));
const ResetPassword = React.lazy(() => import('./components/ResetPassword'));
const Messages = React.lazy(() => import('./components/Messages'));
const TenantDetails = React.lazy(() => import('./components/TenantDetails'));
const PropertyDetails = React.lazy(
  () => import('./components/PropertyDetails')
);
const Tenant = React.lazy(() => import('./components/Tenant'));
const Plantype = React.lazy(() => import('./components/Plantype'));
const AboutUs = React.lazy(() => import('./components/AboutUs'));
const MonthlyAnalysis = React.lazy(
  () => import('./components/MonthlyAnalysis')
);
const YearlyAnalysis = React.lazy(() => import('./components/YearlyAnalysis'));
const ProfitAndLoss = React.lazy(() => import('./components/ProfitAndLoss'));
const Information = React.lazy(() => import('./components/Information'));
const PageNotFound404 = React.lazy(() => import('./components/404'));

const App: React.FC = () => {
  const [isAuth, setAuth] = useState<boolean | null>(false);
  const [isLoading, setLoading] = useState<boolean | null>(true);
  const [userRoleType, setUserRoleType] = useState<string | null | undefined>(
    null
  );
  const [subscriptionType, setSubscriptionType] = useState<
    string | null | undefined
  >(null);
  const [IsSubActive, setIsSubActive] = useState<boolean | undefined>(false);

  Authenticator.Initialize(
    isAuth,
    isLoading,
    userRoleType,
    subscriptionType,
    IsSubActive,
    setAuth,
    setLoading,
    setUserRoleType,
    setSubscriptionType,
    setIsSubActive
  );

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const location = useLocation();

  const getPageSpecificClass = () => {
    const { pathname } = location;
    if (pathname === '/overview') return 'overview';
    if (pathname === '/profile') return 'profile';
    if (pathname === '/property') return 'property';
    if (pathname === '/tenant') return 'tenant';
    if (pathname === '/notifications') return 'notifications';
    if (pathname === '/information') return 'information';
    if (pathname === '/requests') return 'requests';
    if (pathname === '/plantype') return 'plantype';
    if (pathname === '/messages') return 'messages';
    if (pathname.startsWith('/tenant/')) return 'tenant-details';
    if (pathname.startsWith('/property/')) return 'property-details';
    if (pathname === '/monthly-analysis') return 'monthly-analysis';
    if (pathname === '/yearly-analysis') return 'yearly-analysis';
    if (pathname === '/profit-and-loss') return 'profit-and-loss';
    if (pathname === '/about-us') return 'about-us';
    return 'page-not-found';
  };

  return (
    <React.StrictMode>
      <div className="App">
        <AuthProvider>
          {isAuth && <Sidebar />}
          <div
            className={`main-content ${isAuth ? 'authenticated' : ''} ${getPageSpecificClass()}`}
          >
            <Suspense fallback={<div></div>}>
              <Routes>
                {/* @ts-ignore */}
                <Route element={<PublicRoute isAuth={isAuth} to="/overview" />}>
                  <Route path="/" element={<Login />} />
                </Route>
                {/* @ts-ignore */}
                <Route element={<PublicRoute isAuth={isAuth} to="/overview" />}>
                  <Route path="/login" element={<Login />} />
                </Route>
                <Route path="/verify-email/:token" element={<VerifyEmail />} />
                <Route
                  path="/reset-password/:token"
                  element={<ResetPassword />}
                />
                <Route path="/about-us" element={<AboutUs />} />
                <Route
                  element={
                    <ProtectedRoute accessDenied={!isAuth} to="/login" />
                  }
                >
                  <Route path="/overview" element={<Overview />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/property" element={<Property />} />
                  <Route path="/tenant" element={<Tenant />} />
                  <Route path="/notifications" element={<Notification />} />
                  <Route path="/requests" element={<Request />} />
                  <Route path="/plantype" element={<Plantype />} />
                  <Route path="/messages" element={<Messages />} />
                  <Route path="/tenant/:tenantId" element={<TenantDetails />} />
                  <Route
                    path="/property/:propertyId"
                    element={<PropertyDetails />}
                  />
                  <Route
                    path="/monthly-analysis"
                    element={<MonthlyAnalysis />}
                  />
                  <Route path="/yearly-analysis" element={<YearlyAnalysis />} />
                  <Route path="/profit-and-loss" element={<ProfitAndLoss />} />
                  <Route path="/information" element={<Information />} />
                </Route>
                <Route path="*" element={<PageNotFound404 />} />
              </Routes>
            </Suspense>
          </div>
        </AuthProvider>
      </div>
    </React.StrictMode>
  );
};

export default App;
