import React, { useEffect, ReactNode } from 'react';
import { api } from './utils/api';
import { Authenticator } from './helper/Authenticator';
import { ExecutionLimiter } from './utils/ExecutionLimiter';

export const AuthContext = React.createContext<any>(null);

async function authenticate() {
  try {
    const authResult = await api.authUser();
    if (authResult) {
      const user = await api.getUserDetails();
      if (Authenticator.setAuth && Authenticator.setLoading) {
        Authenticator.setAuth(true);
        Authenticator.setLoading(false);
        // @ts-ignore
        Authenticator.setUserRoleType(user.UserRoleType);
        // @ts-ignore
        Authenticator.setSubscriptionType(user.SubscriptionType);
        // @ts-ignore
        Authenticator.setSubActiveSetter(!!user.IsActive);
      }
    } else {
      throw new Error('Authentication failed');
    }
  } catch (e) {
    if (Authenticator.setAuth && Authenticator.setLoading) {
      Authenticator.setAuth(false);
      Authenticator.setLoading(false);
      // @ts-ignore
      Authenticator.setUserRoleType('');
      // @ts-ignore
      Authenticator.setSubscriptionType('');
      // @ts-ignore
      Authenticator.setSubActiveSetter(false);
    }
  }
}

const throttleAuthenticate = ExecutionLimiter.throttle(authenticate, 200);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  useEffect(() => {
    throttleAuthenticate();
  }, []);

  return <AuthContext.Provider value={null}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => React.useContext(AuthContext);
