type AuthStateSetter = (value: boolean | null) => void;
type LoadingStateSetter = (value: boolean | null) => void;
type UserRoleTypeSetter = (value: string | null | undefined) => void;
type SubscriptionTypeSetter = (value: string | null | undefined) => void;
type SubActiveSetter = (value: boolean | undefined) => void;

export class Authenticator {
  static isAuth: boolean | null = null;
  static isLoading: boolean | null = null;
  static userRoleType: string | null | undefined = null;
  static subscriptionType: string | null | undefined = null;
  static isSubActive: boolean | undefined = false;
  static setAuth: AuthStateSetter | null = null;
  static setLoading: LoadingStateSetter | null = null;
  static setUserRoleType: UserRoleTypeSetter | null = null;
  static setSubscriptionType: SubscriptionTypeSetter | null = null;
  static setSubActiveSetter: SubActiveSetter | null = null;

  static Initialize(
    isAuth: boolean | null,
    isLoading: boolean | null,
    userRoleType: string | null | undefined,
    subscriptionType: string | null | undefined,
    isSubActive: boolean | undefined,
    setAuth: AuthStateSetter,
    setLoading: LoadingStateSetter,
    setUserRoleType: UserRoleTypeSetter,
    setSubscriptionType: SubscriptionTypeSetter,
    setSubActiveSetter: SubActiveSetter
  ): void {
    Authenticator.isAuth = isAuth;
    Authenticator.isLoading = isLoading;
    Authenticator.userRoleType = userRoleType;
    Authenticator.subscriptionType = subscriptionType;
    Authenticator.isSubActive = isSubActive === true;
    Authenticator.setAuth = setAuth;
    Authenticator.setLoading = setLoading;
    Authenticator.setUserRoleType = setUserRoleType;
    Authenticator.setSubscriptionType = setSubscriptionType;
    Authenticator.setSubActiveSetter = setSubActiveSetter;
  }
}
