const avatar1 = require('../assets/avatars/memo_3.png');
const avatar2 = require('../assets/avatars/memo_4.png');
const avatar3 = require('../assets/avatars/memo_5.png');
const avatar4 = require('../assets/avatars/memo_6.png');
const avatar5 = require('../assets/avatars/memo_7.png');
const avatar6 = require('../assets/avatars/memo_8.png');
const avatar7 = require('../assets/avatars/memo_9.png');
const avatar8 = require('../assets/avatars/memo_10.png');
const avatar9 = require('../assets/avatars/memo_11.png');
const avatar10 = require('../assets/avatars/memo_14.png');
const avatar11 = require('../assets/avatars/memo_16.png');
const avatar12 = require('../assets/avatars/memo_21.png');
const avatar13 = require('../assets/avatars/memo_26.png');
const avatar14 = require('../assets/avatars/memo_30.png');
const avatar15 = require('../assets/avatars/memo_17.png');

const avatars = [
  avatar1,
  avatar2,
  avatar3,
  avatar4,
  avatar5,
  avatar6,
  avatar7,
  avatar8,
  avatar9,
  avatar10,
  avatar11,
  avatar12,
  avatar13,
  avatar14,
  avatar15,
];

export default avatars;
